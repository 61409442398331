<template>
  <section class="container">
    <b-field label="Admin">
      <b-input v-model="tokenConfig.admin"></b-input>
    </b-field>
    <b-radio v-model="tokenConfig.type" name="st" native-value="fa2">
      FA2
    </b-radio>
    <b-radio v-model="tokenConfig.type" name="st" native-value="fa12">
      FA12
    </b-radio>

    <b-field label="Symbol">
      <b-input v-model="tokenConfig.symbol"></b-input>
    </b-field>
    <b-field label="Name">
      <b-input v-model="tokenConfig.name"></b-input>
    </b-field>
    <b-field label="Thumbnail URL">
      <b-input v-model="tokenConfig.thumbnailUri"></b-input>
    </b-field>
    <b-field label="Decimals">
      <b-numberinput
        v-model="tokenConfig.decimals"
        min="0"
      ></b-numberinput>
    </b-field>
    <b-field label="Total Supply" title="whatever decimals so 1 token with 6 decimals should be 1000000 there">
      <b-input v-model="tokenConfig.totalSupply"></b-input>
    </b-field>
    
    
    <br />
    <b-button type="is-primary" v-if="!inProgress && !error" @click="deploy">Deploy</b-button>
    <b-button type="is-primary" v-if="inProgress && !error" loading>Loading</b-button>
    <b-button v-if="error" type="is-danger" @click="error=''">{{error}}</b-button>

    <span v-if="contractAddress">
      Contract deployed: {{ contractAddress }}
    </span>
  </section>
</template>

<script>
const { MichelsonMap } = require("@taquito/michelson-encoder");

export default {
  data: function () {
    return {
      tokenConfig: {
        admin: window.deployer.account.address,
        type: "fa2",
        symbol: "",
        name: "",
        thumbnailUri: "",
        decimals: 6,
        totalSupply: "10000000000000000000000"
      },
      deployer: window.deployer,
      inProgress: false,
      error: "",
      contractAddress: null,
    };
  },
  methods: {
    deploy() {
      let tokenStorage = null;
      if (this.tokenConfig.type === "fa2") {
        tokenStorage = {
          total_supply:  this.tokenConfig.totalSupply,
          paused: false,
          admin: this.tokenConfig.admin,
          minters: [],
          metadata: MichelsonMap.fromLiteral({}),
          operators_allowed: true,
          ledger: MichelsonMap.fromLiteral({
            [this.tokenConfig.admin]: {
              balance: this.tokenConfig.totalSupply,
              allowances: [],
            },
          }),
          token_metadata: MichelsonMap.fromLiteral({
            0: {token_id: '0',
            token_info: MichelsonMap.fromLiteral({
              symbol: Buffer.from(this.tokenConfig.symbol).toString("hex"),
              name: Buffer.from(this.tokenConfig.name).toString("hex"),
              decimals: Buffer.from("" + this.tokenConfig.decimals).toString("hex"),
              thumbnailUri: Buffer.from(
                this.tokenConfig.thumbnailUri
              ).toString("hex"),
            }),
          }}),
        };
      } else {
        tokenStorage = {
          owner: this.tokenConfig.admin,
          totalSupply: this.tokenConfig.totalSupply,
          minters: [],
          ledger: MichelsonMap.fromLiteral({
            [this.tokenConfig.admin]: {
              balance: this.tokenConfig.totalSupply,
              allowances: new MichelsonMap(),
            }
          }),
          token_metadata: MichelsonMap.fromLiteral({
            0: {token_id: '0',
            token_info: MichelsonMap.fromLiteral({
              symbol: Buffer.from(this.tokenConfig.symbol).toString("hex"),
              name: Buffer.from(this.tokenConfig.name).toString("hex"),
              decimals: Buffer.from("" + this.tokenConfig.decimals).toString("hex"),
              thumbnailUri: Buffer.from(
                this.tokenConfig.thumbnailUri
              ).toString("hex"),
            }),
          }}),
        };
      }

      console.log(tokenStorage);
      console.log(window.deployer.tezos);
      this.inProgress = true;
      window.deployer
        .deployToken(this.tokenConfig.type, tokenStorage)
        .then((contract) => {
          this.inProgress = false;
          this.contractAddress = contract.address;
        })
        .catch((e) => {
          this.inProgress = false;
          console.error(e);
          this.error = e.toString();
        });
    },
    getAccountAddress() {
      return window.deployer.account.address;
    },
  },
};
</script>