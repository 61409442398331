<template>
  <div id="app">
      <span v-if="deployer.account">{{deployer.account.address}}</span>
      <b-field>
      <b-select v-model="network">
        <option
                    v-for="net in networks"
                    :value="net"
                    :key="net">
                    {{ net}}
                </option>
      </b-select>
      </b-field>
      <b-button v-if="deployer.account" type="is-primary is-light" @click="deployer.logout()">Logout</b-button>
      <b-button v-if="!deployer.account" type="is-primary" @click="deployer.login()">Login</b-button>
      <div class="box">
      <token-deployer v-if="deployer.account"/>
      </div>
    
  </div>
</template>

<script>
import TokenDeployer from './components/TokenDeployer.vue'

export default {
  name: 'App',
  mounted() {
    this.deployer.setNetwork("testnet")
  },
  data: function() {
    return {
      deployer: window.deployer,
      networks: ['testnet', 'mainnet'],
      network: "testnet"
    }
  },
  watch: {
    network(newValue) {
      console.log('Network selected')
      window.network = newValue;
      this.deployer.setNetwork(newValue)
    }
  },
  components: {
    TokenDeployer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
